import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import jwtDecode from 'jwt-decode';
import { Store } from '@ngrx/store';
import { UserState } from '../stores/user/user.reducer';
import { UserService } from './user.service';
import { Observable, of } from "rxjs";
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  private pathPriorities = environment.pathPriorities;
  private hasNavigatedOnce = false; 

  constructor(
    private router: Router,
    private userStore: Store<UserState>,
    private userService: UserService,) { }

  canActivate(route: ActivatedRouteSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
    const authToken = localStorage.getItem('authToken');
    if (authToken) {
      const decoded = jwtDecode(authToken);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('role');
        localStorage.removeItem('customerId');
        localStorage.removeItem('filteTerms');
        localStorage.removeItem('manageGroupQrfilteTerms');
        localStorage.removeItem('notificationMode');
        this.router.navigate(['/signin']);
        
} else {
  return this.userService.getUserPermissions().pipe(
    map((res: any) => {
      const requestedPath = route.routeConfig?.path;
      if (res && res.permissions) {
        if (!this.hasNavigatedOnce) {
          if (requestedPath && res.permissions.includes(requestedPath)) {
            this.hasNavigatedOnce = true;
            return true;
          }

          const availablePath = this.pathPriorities.find(path =>
            res.permissions.includes(path.name)
          );

          if (availablePath) {
            this.router.navigate([availablePath.path]);
            this.hasNavigatedOnce = true; 
            return false;
          }

          this.router.navigate(['/page-not-found']);
          return false;
        } else {
          // the check need to allow path such as `/customer/:pid/:custId` which need to be check
          // against `customer`, require regular expression
          const allowedNavigation = res.permissions.find(permission => new RegExp(permission).test(requestedPath))
          if (requestedPath && allowedNavigation) {
            return true; 
          }
          this.router.navigate(['/page-not-found']);
          return false;
        }
      }
      this.router.navigate(['/signin']);
      return false;
    }),
    catchError(() => {
      this.router.navigate(['/signin']);
      return of(false);
    })
  );
}
} else {
this.router.navigate(['/signin']);
return false;
}
}
}